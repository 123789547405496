export const CREATE_NEW_CHART = 'CREATE_NEW_CHART';
export const ON_CREATE_NEW_CHART_SUCCESS = 'ON_CREATE_NEW_CHART_SUCCESS';
export const ON_CREATE_NEW_CHART_ERROR = 'ON_CREATE_NEW_CHART_ERROR';

export const ON_REFETCH_SCATTER_CHART_START = 'ON_REFETCH_SCATTER_CHART_START';
export const ON_REFETCH_SCATTER_CHART_SUCCESS =
  'ON_REFETCH_SCATTER_CHART_SUCCESS';
export const ON_REFETCH_SCATTER_CHART_ERROR = 'ON_REFETCH_SCATTER_CHART_ERROR';

export const ON_REFETCH_HISTOGRAM_CHART_START =
  'ON_REFETCH_HISTOGRAM_CHART_START';
export const ON_REFETCH_HISTOGRAM_CHART_SUCCESS =
  'ON_REFETCH_HISTOGRAM_CHART_SUCCESS';
export const ON_REFETCH_HISTOGRAM_CHART_ERROR =
  'ON_REFETCH_HISTOGRAM_CHART_ERROR';

export const ON_REFETCH_DOE_CHART_START = 'ON_REFETCH_DOE_CHART_START';
export const ON_REFETCH_DOE_CHART_SUCCESS = 'ON_REFETCH_DOE_CHART_SUCCESS';
export const ON_REFETCH_DOE_CHART_ERROR = 'ON_REFETCH_DOE_CHART_ERROR';

export const ON_REFETCH_SCATTER_FOR_MATRIX_CHART_START =
  'ON_REFETCH_SCATTER_FOR_MATRIX_CHART_START';
export const ON_REFETCH_SCATTER_FOR_MATRIX_CHART_SUCCESS =
  'ON_REFETCH_SCATTER_FOR_MATRIX_CHART_SUCCESS';
export const ON_REFETCH_SCATTER_FOR_MATRIX_CHART_ERROR =
  'ON_REFETCH_SCATTER_FOR_MATRIX_CHART_ERROR';

export const SPLIT_VISUALIZE_DETAILS_DISPLAY =
  'SPLIT_VISUALIZE_DETAILS_DISPLAY';

export const SET_ACTIVE_VISUALIZE_HEADER_TAB =
  'SET_ACTIVE_VISUALIZE_HEADER_TAB';
export const ON_ADD_NEW_CHART_TAB_CLICK = 'ON_ADD_NEW_CHART_TAB_CLICK';
export const ON_DOWNLOAD_CHART_CLICK = 'ON_DOWNLOAD_CHART_CLICK';
export const ON_DOWNLOAD_CHART_FINISHED = 'ON_DOWNLOAD_CHART_FINISHED';

export const UPDATE_CHART_SETTINGS = 'UPDATE_CHART_SETTINGS';
export const DELETE_VISUALIZE_CHART = 'DELETE_VISUALIZE_CHART';

export const ON_FETCH_CHART_RAW_DATA_START = 'ON_FETCH_CHART_RAW_DATA_START';
export const ON_FETCH_CHART_RAW_DATA_SUCCESS =
  'ON_FETCH_CHART_RAW_DATA_SUCCESS';
export const ON_FETCH_CHART_RAW_DATA_ERROR = 'ON_FETCH_CHART_RAW_DATA_ERROR';
