export const getFilesMatrixModelDef = (
  rowData: Record<string, string>,
): string[][] =>
  Object.keys(rowData)
    .filter((key) => key !== 'id')
    .map((key) => {
      if (key === 'file_id' || key === 'row') {
        return [key, 'int'];
      }
      return [key, 'str'];
    });
