export const FETCH_USER_INFO_START = 'FETCH_USER_INFO_START';
export const FETCH_USER_INFO_SUCCESS = 'FETCH_USER_INFO_SUCCESS';
export const FETCH_USER_INFO_ERROR = 'FETCH_USER_INFO_ERROR';

export const LOGIN_START = 'LOGIN_START';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_REFRESH_START = 'LOGIN_REFRESH_START';
export const LOGIN_REFRESH_SUCCESS = 'LOGIN_REFRESH_SUCCESS';
export const LOGIN_REFRESH_ERROR = 'LOGIN_REFRESH_ERROR';
export const LOGOUT_START = 'LOGOUT_START';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_ERROR = 'LOGOUT_ERROR';

export const AUTH_PWCHANGE_START = 'AUTH_PWCHANGE_START';
export const AUTH_PWCHANGE_SUCCESS = 'AUTH_PWCHANGE_SUCCESS';
export const AUTH_PWCHANGE_ERROR = 'AUTH_PWCHANGE_ERROR';
export const AUTH_PWFORGOT_START = 'AUTH_PWFORGOT_START';
export const AUTH_PWFORGOT_SUCCESS = 'AUTH_PWFORGOT_SUCCESS';
export const AUTH_PWFORGOT_ERROR = 'AUTH_PWFORGOT_ERROR';
export const AUTH_PWFORGOT_CONFIRM_START = 'AUTH_PWFORGOT_CONFIRM_START';
export const AUTH_PWFORGOT_CONFIRM_SUCCESS = 'AUTH_PWFORGOT_CONFIRM_SUCCESS';
export const AUTH_PWFORGOT_CONFIRM_ERROR = 'AUTH_PWFORGOT_CONFIRM_ERROR';
export const AUTH_RESET_FORM = 'AUTH_RESET_FORM';

export const AUTH_SIGNUP_CHECK_USER_STATUS_START =
  'AUTH_SIGNUP_CHECK_USER_STATUS_START';
export const AUTH_SIGNUP_CHECK_USER_STATUS_SUCCESS =
  'AUTH_SIGNUP_CHECK_USER_STATUS_SUCCESS';
export const AUTH_SIGNUP_CHECK_USER_STATUS_ERROR =
  'AUTH_SIGNUP_CHECK_USER_STATUS_ERROR';
export const AUTH_SIGNUP_CHECK_USER_STATUS_RESET =
  'AUTH_SIGNUP_CHECK_USER_STATUS_RESET';
export const AUTH_SIGNUP_CHANGE_STEP = 'AUTH_SIGNUP_CHANGE_STEP';

export const CHECK_INVITATION_START = 'CHECK_INVITATION_START';
export const CHECK_INVITATION_SUCCESS = 'CHECK_INVITATION_SUCCESS';
export const CHECK_INVITATION_ERROR = 'CHECK_INVITATION_ERROR';

export const CREATE_INVITED_USER_START = 'CREATE_INVITED_USER_START';
export const CREATE_INVITED_USER_SUCCESS = 'CREATE_INVITED_USER_SUCCESS';
export const CREATE_INVITED_USER_ERROR = 'CREATE_INVITED_USER_ERROR';

export const ACCEPT_INVITE_START = 'ACCEPT_INVITE_START';
export const ACCEPT_INVITE_SUCCESS = 'ACCEPT_INVITE_SUCCESS';
export const ACCEPT_INVITE_ERROR = 'ACCEPT_INVITE_ERROR';
