import { Controller, ControllerProps } from 'react-hook-form';
import styled from 'styled-components';

import {
  Autocomplete,
  AutocompleteRenderInputParams,
  FormControl,
  TextField,
} from '@mui/material';

interface SelectWithModelChoicesProps extends Partial<ControllerProps> {
  fieldName: string;
  choices: Array<any>;
  label: string;
  control: any;
  error?: string;
  icon?: React.ReactNode;
  multiple?: boolean;
  placeholder?: string;
  notRequired?: boolean;
  disabled?: boolean;
  withoutIcon?: boolean;
  disableClearable?: boolean;
  forceShowClearButton?: boolean;
  withoutInput?: boolean;
  limit?: number;
}

const SelectWithModelChoices = ({
  fieldName,
  error,
  choices = [],
  label,
  control,
  icon = null,
  multiple,
  placeholder,
  notRequired,
  withoutIcon,
  forceShowClearButton,
  limit,
  ...rest
}: SelectWithModelChoicesProps) => (
  <Wrapper forceShowClearButton={forceShowClearButton}>
    {withoutIcon ? null : <IconWrap>{icon}</IconWrap>}
    <FormControl fullWidth>
      <Controller
        name={fieldName}
        control={control}
        defaultValue={multiple ? [] : (rest.defaultValue ?? null)}
        rules={{
          validate: (value) => {
            if (notRequired) {
              return true;
            }
            if (multiple && limit && value.length > limit)
              return `Only ${limit} options could be selected.`;
            if (
              !notRequired &&
              (!value || (value !== null && value.length === 0))
            ) {
              return 'Required';
            } else {
              return value;
            }
          },
        }}
        render={({ field: { value, onChange } }) => {
          return (
            <Autocomplete
              fullWidth
              autoHighlight
              openOnFocus
              options={choices}
              value={value}
              onChange={(event, options) => {
                onChange(options);
              }}
              multiple={multiple}
              getOptionLabel={(option) => option.name || option.name_display}
              isOptionEqualToValue={(option, value) => {
                if ('db_id' in option) {
                  return option.db_id == value.db_id;
                } else {
                  return option.name == value.name;
                }
              }}
              {...rest}
              renderInput={(params: AutocompleteRenderInputParams) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: multiple ? (
                      params.InputProps.startAdornment
                    ) : (
                      <></>
                    ),
                  }}
                  label={label}
                  variant="filled"
                  margin="dense"
                  placeholder={placeholder}
                  error={Boolean(error)}
                  helperText={error}
                />
              )}
            />
          );
        }}
      />
    </FormControl>
  </Wrapper>
);

const Wrapper = styled.div<{ forceShowClearButton?: boolean }>`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  .MuiAutocomplete-clearIndicator {
    visibility: ${({ forceShowClearButton }) =>
      forceShowClearButton ? 'visible' : 'none'};
  }
`;

const IconWrap = styled.div`
  display: flex;
  width: 43px;
  padding-top: 7px;
  color: ${({ theme: { colors } }) => colors.black};

  .MuiSvgIcon-root {
    color: ${({ theme: { colors } }) => colors.black} !important;
  }
`;

export default SelectWithModelChoices;
