export const FETCH_ACTIVE_USERS_START = 'FETCH_ACTIVE_USERS_START';
export const FETCH_ACTIVE_USERS_SUCCESS = 'FETCH_ACTIVE_USERS_SUCCESS';
export const FETCH_ACTIVE_USERS_ERROR = 'FETCH_ACTIVE_USERS_ERROR';

export const FETCH_ACTIVE_INVITATIONS_START = 'FETCH_ACTIVE_INVITATIONS_START';
export const FETCH_ACTIVE_INVITATIONS_SUCCESS =
  'FETCH_ACTIVE_INVITATIONS_SUCCESS';
export const FETCH_ACTIVE_INVITATIONS_ERROR = 'FETCH_ACTIVE_INVITATIONS_ERROR';

export const FETCH_ORG_ROLES_START = 'FETCH_ORG_ROLES_START';
export const FETCH_ORG_ROLES_SUCCESS = 'FETCH_ORG_ROLES_SUCCESS';
export const FETCH_ORG_ROLES_ERROR = 'FETCH_ORG_ROLES_ERROR';

export const CREATE_AND_SEND_INVITATION_START = 'CREATE_INVITATION_START';
export const CREATE_AND_SEND_INVITATION_SUCCESS = 'CREATE_INVITATION_SUCCESS';
export const CREATE_AND_SEND_INVITATION_ERROR = 'CREATE_INVITATION_ERROR';

export const SEND_INVITE_EMAIL_START = 'SEND_INVITE_EMAIL_START';
export const SEND_INVITE_EMAIL_SUCCESS = 'SEND_INVITE_EMAIL_SUCCESS';
export const SEND_INVITE_EMAIL_ERROR = 'SEND_INVITE_EMAIL_ERROR';

export const DELETE_INVITATION_START = 'DELETE_INVITATION_START';
export const DELETE_INVITATION_SUCCESS = 'DELETE_INVITATION_SUCCESS';
export const DELETE_INVITATION_ERROR = 'DELETE_INVITATION_ERROR';

export const DELETE_USER_START = 'DELETE_USER_START';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_ERROR = 'DELETE_USER_ERROR';

export const UPDATE_USER_ROLE_START = 'UPDATE_USER_ROLE_START';
export const UPDATE_USER_ROLE_SUCCESS = 'UPDATE_USER_ROLE_SUCCESS';
export const UPDATE_USER_ROLE_ERROR = 'UPDATE_USER_ROLE_ERROR';

export const UPDATE_ORG_NAME_START = 'UPDATE_ORG_NAME_START';
export const UPDATE_ORG_NAME_SUCCESS = 'UPDATE_ORG_NAME_SUCCESS';
export const UPDATE_ORG_NAME_ERROR = 'UPDATE_ORG_NAME_ERROR';
