import loadable from '@loadable/component';
import { Route, Routes } from 'react-router-dom';

import Preloader from 'components/Preloader/Preloader';

const SignUpPage = loadable(() => import('./modules/auth/pages/SignUpPage'), {
  fallback: <Preloader />,
});

const SignUpInvitedPage = loadable(
  () => import('./modules/auth/pages/SignUpPageInvited'),
  {
    fallback: <Preloader />,
  },
);

const LoginPage = loadable(() => import('./modules/auth/pages/LoginPage'), {
  fallback: <Preloader />,
});
const UpdatePasswordPage = loadable(
  () => import('./modules/auth/pages/UpdatePasswordPage'),
  {
    fallback: <Preloader />,
  },
);
const ForgotPasswordPage = loadable(
  () => import('./modules/auth/pages/ForgotPasswordPage'),
  {
    fallback: <Preloader />,
  },
);
const PasswordUpdateSuccessPage = loadable(
  () => import('./modules/auth/pages/PasswordUpdateSuccessPage'),
  {
    fallback: <Preloader />,
  },
);
const PasswordResetSentPage = loadable(
  () => import('./modules/auth/pages/PasswordResetSentPage'),
  {
    fallback: <Preloader />,
  },
);

const InvitePage = loadable(() => import('./modules/auth/pages/InvitePage'), {
  fallback: <Preloader />,
});

export default (
  <Routes>
    <Route path="/auth/sign-up" element={<SignUpPage />} />
    <Route path="/auth/sign-up-invited" element={<SignUpInvitedPage />} />
    <Route path="/auth/login" element={<LoginPage />} />
    <Route path="/auth/confirmforgot" element={<UpdatePasswordPage />} />
    <Route path="/auth/forgot-password" element={<ForgotPasswordPage />} />
    <Route
      path="/auth/password-update-success"
      element={<PasswordUpdateSuccessPage />}
    />
    <Route
      path="/auth/password-reset-sent"
      element={<PasswordResetSentPage />}
    />
    <Route path="/invite" element={<InvitePage />} />
  </Routes>
);
