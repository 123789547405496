import * as R from 'ramda';

import * as actionTypes from 'modules/GlobalActionsTypes';
import { mockData } from './mockData';

export const STATE_KEY = 'filesMatrix';

const initialState = {
  filesMatrix: {
    sourceModel: 'KFIL',
    filesMatrixSelectedFiles: [
      { filename: 'matrix_test02.xlsx', id: 41 },
      { filename: 'matrix_test01.xlsx', id: 44 },
      { filename: 'matrix_test03.xlsx', id: 45 },
    ],
    loading: false,
    data: [],
    error: false,
    filterModel: {},
    showCancel: false,
    selectedNodes: [],
    sortModel: [],
  },
};

const FilesMatrixReducer = (state = initialState, action: any) => {
  const { payload } = action;

  switch (action.type) {
    case actionTypes.FETCH_FILES_MATRIX_START: {
      return R.mergeDeepRight(state, { filesMatrix: { loading: true } });
    }
    case actionTypes.FETCH_FILES_MATRIX_SUCCESS: {
      const shouldUpdateState = payload.shouldUpdateState;

      if (!shouldUpdateState) {
        return R.mergeDeepRight(state, {
          filesMatrix: {
            loading: false,
          },
        });
      }

      return R.mergeDeepRight(state, {
        filesMatrix: {
          data: R.propOr([], 'rows', payload),
          lastRow: R.propOr(0, 'lastRow', payload),
          loading: false,
        },
      });
    }
    case actionTypes.FETCH_FILES_MATRIX_ERROR: {
      return R.mergeDeepRight(state, {
        filesMatrix: {
          data: R.propOr([], 'rows', mockData),
          lastRow: R.propOr(0, 'lastRow', mockData),
          loading: false,
        },
      });
    }

    case actionTypes.SET_FILES_MATRIX_TABLE_FILTER: {
      return R.mergeRight(state, {
        filesMatrix: { ...state.filesMatrix, filterModel: payload },
      });
    }
    case actionTypes.SET_FILES_MATRIX_TABLE_SORT: {
      return R.mergeRight(state, {
        filesMatrix: { ...state.filesMatrix, sortModel: payload },
      });
    }
    case actionTypes.SET_FILES_MATRIX_SHOW_CANCEL: {
      return R.mergeDeepRight(state, {
        filesMatrix: { showCancel: !state.filesMatrix.showCancel },
      });
    }

    case actionTypes.SET_FILES_MATRIX_SELECTED_NODES: {
      return R.mergeDeepRight(state, {
        filesMatrix: { selectedNodes: payload },
      });
    }
    case actionTypes.CLEAR_FILES_MATRIX_TABLE: {
      return initialState;
    }
    case actionTypes.FILES_MATRIX_ADD_FILE_TO_MATRIX: {
      const { sourceModel, selectedFile } = payload;

      if (state.filesMatrix.sourceModel === sourceModel) {
        return R.mergeRight(state, {
          filesMatrix: {
            ...state.filesMatrix,
            filesMatrixSelectedFiles: [
              ...state.filesMatrix.filesMatrixSelectedFiles,
              selectedFile,
            ],
          },
        });
      } else {
        return R.mergeRight(state, {
          filesMatrix: {
            ...state.filesMatrix,
            sourceModel,
            filesMatrixSelectedFiles: [selectedFile],
          },
        });
      }
    }
    default:
      return state;
  }
};

export default FilesMatrixReducer;
